import { Buffer } from 'buffer';

import apiBaseService from './apiBaseService'

import { useAppStore } from '../../store/app';
import { useAuthStore } from '../../store/auth';

export default {
  mixins: [apiBaseService],

  data () {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
    }
  },

  computed: {
    token () {
      return (this.authStore.token) ? this.authStore.token : '';
    },
  },

  methods: {
    async exportApplications () {
      const { currentTab, currentProduct } = this.appStore;
      const url = `/export-applications/${currentTab}/${currentProduct}`;

      let partnerToken = await this.getPartnerApiToken();

      const response = await this.partnerApiRequest({
        method: 'GET',
        headers: { authorization: `Bearer ${partnerToken}` },
        url,
      });

      if ([200, 409].includes(response.responseCode)) {
        return response;
      }

      partnerToken = await this.retryLoginToPartnerApi();

      return await this.partnerApiRequest({
        method: 'GET',
        headers: { authorization: `Bearer ${partnerToken}` },
        url,
      });
    },

    // get all applications for stage
    async getApplications (limit, nextCursor) {
      const { currentTab, currentSubTab, currentProduct } = this.appStore;
      let url = `/partner/list/${currentTab || 'lead'}/${currentProduct}`;
      const queryParams = {
        ...(currentSubTab && { subStage: currentSubTab }),
        ...(limit && { limit }),
        ...(nextCursor && { nextCursor: Buffer.from(JSON.stringify(nextCursor)).toString('base64') })
      }
      const queryParamString = queryParams && Object.keys(queryParams).reduce((acc, key) => {
        if (!acc) return `${key}=${queryParams[key]}`;
        acc += `&${key}=${queryParams[key]}`;
        return acc;
      }, '');
      if (queryParamString) url += `?${queryParamString}`;
      return await this.apiRequest({
        method: 'get',
        headers: { authorization: this.token },
        url,
      })
    },

    async getApplicationWithPagination () {
      const { appPaginationSize, appNextCursor, currentTab } = this.appStore;
      if (currentTab === 'given') {
        return await this.getApplications(appPaginationSize, appNextCursor);
      } else {
        return await this.getApplications();
      }
    },

    // get singular application
    async getApplication (stage, appId) {
      const { currentProduct } = this.appStore;
      return await this.apiRequest({
        method: 'get',
        headers: { authorization: this.token },
        url: `/partner/list/${stage}/${currentProduct}/${appId}`
      });
    },
    async getDomains () {
      return await this.apiRequest({
        method: 'get',
        url: '/domains'
      })
    },
    async searchApplication (query) {
      const { currentProduct } = this.appStore;
      return await this.apiRequest({
        method: 'post',
        headers: { authorization: this.token },
        data: { product: currentProduct, query },
        url: '/partner/search-apps',
      });
    },
    async getApplicationMyinfoData (appId) {
      const { currentProduct } = this.appStore;
      const url = `/myinfo-data/${appId}/${currentProduct}`;

      let partnerToken = await this.getPartnerApiToken();

      const response = await this.partnerApiRequest({
        method: 'GET',
        headers: { authorization: `Bearer ${partnerToken}` },
        url,
      });

      if ([200, 409].includes(response.responseCode)) {
        return response;
      }

      partnerToken = await this.retryLoginToPartnerApi();

      return await this.partnerApiRequest({
        method: 'GET',
        headers: { authorization: `Bearer ${partnerToken}` },
        url,
      });
    },
    async retrieveFiles (appId, product) {
      return await this.apiRequest({
        method: 'post',
        url: '/auth/gets3url',
        headers: { authorization: this.token },
        data: {
          appId,
          userGroup: product,
        }
      })
    },
    async retrieveAppointments () {
      const { currentProduct } = this.appStore;
      return await this.apiRequest({
        method: 'get',
        headers: { authorization: this.token },
        url: `/partner/appointment-calendar/${currentProduct}`,
      });
    },
    async retrievePerformanceStats () {
      // get currentproduct which is also the product internal name
      const partnerToken = await this.getPartnerApiToken();
      const { currentProduct } = this.appStore;
      return await this.partnerApiRequest({
        method: 'GET',
        headers: { authorization: `Bearer ${partnerToken}` },
        url: `/products/${currentProduct}/performance-metrics`,
      });
    }
  },
}
